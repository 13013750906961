import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { Parallax, Background } from "react-parallax"
import { StaticImage } from "gatsby-plugin-image"
import { screenSizes } from "model"

const StyledParallax = styled(Parallax)`
  .react-parallax-background-children {
    width: 100%;
  }
`

const DesktopParallax = styled(StyledParallax)`
  height: 500px;

  @media (max-width: ${screenSizes.tablet}px) {
    display: none;
  }
`
const MobileParallax = styled(StyledParallax)`
  display: none;
  height: 150px;

  @media (max-width: ${screenSizes.tablet}px) {
    display: block;
  }
`

interface Props {
  imageSrc: string
}

const LPParallaxBlock: FC<Props> = ({ imageSrc }) => {
  return (
    <div>
      <DesktopParallax strength={200}>
        <Background>
          <img src={imageSrc} />
        </Background>
      </DesktopParallax>
      <MobileParallax strength={30}>
        <Background>
          <img src={imageSrc} />
        </Background>
      </MobileParallax>
    </div>
  )
}

export default LPParallaxBlock
